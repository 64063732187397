import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/fNrWIORCUT4">
    <SEO title="As the World Turns - If Not Us, Who?" />
  </Layout>
)

export default SermonPost
